module.exports = {
  siteMetadata: {
    title: `Ziegenhagel Media`,
    longtitle: `Ziegenhagel Media - Webseite & Imagefilm | Medienagentur aus Mainz`,
    description: 'Neue Kunden gewinnen, an sich binden und den Platz auf dem Markt sichern. \n' +
      'Unsere breite Aufstellung bietet Ihnen komplette Lösungen an, um Ihr Erscheinungsbild in allen Medien perfekt zu präsentieren. \n' +
      'Wir freuen uns auf ein persönliches Erstgespräch und auf die Gelegenheit, Sie mit einem Blick von Außen für neue Wege zu begeistern!',
    keywords: `Emmendingen,Freiburg,Webseite,Design,Werbeagentur,kostengünstig,billig,professionel,imagefilm,werbevideo,videoproduktion,jung,kreativ`,
    author: `Dominik Ziegenhagel`,
    phone: `01525 87 99 87 1`,
    // phone: `0351 48509180`,
  },
  plugins: [
    //trackingId: "UA-151436863-1",
    /*
  {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-151436863-1",
        // Defines where to place the tracking script - `true` in the head and `false` in the body
        head: false,
        // Setting this parameter is optional
        anonymize: true,
        // Setting this parameter is also optional
        respectDNT: true,
        // Delays sending pageview hits on route update (in milliseconds)
        pageTransitionDelay: 0,
        sampleRate: 5,
        siteSpeedSampleRate: 10,
        cookieDomain: "ziegenhagel.com",
      },
    },
     */
/*
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: "UA-87946648-1",
          // Setting this parameter is optional
          cookieName: "gatsby-gdpr-google-analytics",
          anonymize: true
        },
        // Defines the environments where the tracking should be available  - default is ["production"]
        environments: ['production', 'development']
      },
    },
 */
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `https://www.ziegenhagel.com/`,
        background_color: `#cb4`,
        theme_color: `#cb4`,
        display: `minimal-ui`,
        icon: `src/images/gatsby-icon.png`, // This path is relative to the root of the site.
      },
    },
  {
    resolve: `gatsby-plugin-netlify`,
    options: {
      headers: {"/*": [
        "Strict-Transport-Security: max-age=31536000; includeSubDomains; preload",
        // TODO reenable CSP
        //"Content-Security-Policy:  default-src 'none'; connect-src 'self' https://api.ziegenhagel.com/; font-src 'self'; form-action https://api.ziegenhagel.com; img-src 'self' data: https://premium.webgo.de; media-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'unsafe-inline' "
      ]}, // option to add more headers. `Link` headers are transformed by the below criteria
      allPageHeaders: [], // option to add headers for all pages. `Link` headers are transformed by the below criteria
      mergeSecurityHeaders: true, // boolean to turn off the default security headers
      mergeLinkHeaders: true, // boolean to turn off the default gatsby js headers
      mergeCachingHeaders: true, // boolean to turn off the default caching headers
      transformHeaders: (headers, path) => headers, // optional transform for manipulating headers under each path (e.g.sorting), etc.
      generateMatchPathRewrites: true, // boolean to turn off automatic creation of redirect rules for client only paths
    },
  }
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ]
  ,
  flags: {
    DEV_SSR: true,
    FAST_DEV: true,
    PRESERVE_WEBPACK_CACHE: false,
    PRESERVE_FILE_DOWNLOAD_CACHE: true,
    FAST_REFRESH: true,
    PARALLEL_SOURCING: false



  }

}
