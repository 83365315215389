import { Link } from "gatsby"
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faExternalLinkAlt, faTimes, faBars } from '@fortawesome/free-solid-svg-icons'

import logo_anim from "../images/logo.mp4"
import logo_static from "../images/logo.jpg"

const nav = [
  {
    title: "Grün",
    icon: null,
    to: "/gogreen"
  },
  {
    title: "Portfolio",
    icon: null,
    to: "/stories"
  },
  {
    title: "Angebot",
    icon: null,
    to: "/services"
  },
  {
    title: "Support",
    icon: null,
    to: "/support"
  }/*,
  {
    title: "Dominik",
    icon: faExternalLinkAlt,
    to: "https://dominik.ziegenhagel.com/"
  }*/
]


export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { mobileCollapsed: false }
  }
  render() {
    return (

      <header id="nav-top" className={(this.props.isDark ? "is-dark":"") + " " + (this.state.mobileCollapsed ? "mobile-collapsed" : "mobile-not-collapsed")}>
        <div className="container" >
            <Link className="nolink" to="/"><div className="nav-left" >
                <video onContextMenu={()=>{window.location.href="https://assets.ziegenhagel.com"}} playsInline={true} poster={logo_static} id="logo-anim" alt="Logo" autoPlay={true} muted={true}>
                <source src={logo_anim} type='video/mp4' />
                <img alt="Ziegenhagel Media Logo" src={logo_static}/>
            </video>
            <div className="nav-logo-text">Ziegenhagel <span style={{color:"#777"}}>Media</span></div>
          </div>
          </Link>
          <div className="nav-hamburger" onClick={() => { this.setState({ mobileCollapsed: !this.state.mobileCollapsed }) }} >
            <div id="nav-icon2" className={this.state.mobileCollapsed ? "open" : "closed"}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="nav-right">
            {nav.map(item => {

              let linktext = <>
                {item.icon !== null && <FontAwesomeIcon icon={item.icon} />}
                {item.icon !== null && item.title !== null && <>&ensp;</>}
                {item.title !== null && item.title}
              </>

              return item.to.substr(0, 4) === "http" ?
                <a key={item.to} rel="noopener noreferrer" target="_blank" href={item.to}>
                  {linktext}
                </a>
                :
                <Link key={item.to}
                  activeClassName="active"
                  partiallyActive={true}
                      onDoubleClick={()=>{if(item.title=="Support")window.location.href='/pin'}}
                  to={item.to} >
                  {linktext}
                </Link>

            })}
          </div>
        </div>
      </header>
    )
  }
}
