/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from "gatsby"

import { siteMetadata } from "../../gatsby-config"

import Header from "./header"
import "./layout.css"
import { Link } from "gatsby"
import React, { Component } from "react"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPlusCircle,
  faExternalLinkAlt,
  faPhone,
  faMap,
  faChevronLeft,
  faTimes,
  faCheck,
  faShieldAlt,
  faHandPaper,
  faBan,
} from "@fortawesome/free-solid-svg-icons"
// import Cookies from 'universal-cookie';
import CookieConsent, { Cookies } from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const cookies = new Cookies()

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {

      showPopup: false,
      searchTerm: "",
      cookiesAccepted: true, /*cookies.get("cookies-accepted") == "true"*/
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showPopup: true })
    }, 1100)
  }

  closePopup = () => {
    this.setState({ showPopup: false })
  }

  // a class thats gets the url (some urls have new urls mapped, others fall back to https://faktorxmensch.com
  getNewUrl = () => {
    const url = window.location.pathname.toLowerCase().trim().replace(/\/$/, "")
    const newUrls = {
      "/praktikum": "https://faktorxmensch.com/praktikum",
      "/stories": "https://faktorxmensch.com/portfolio",
      "/services": "https://faktorxmensch.com/dienstleistungen",
      "/support": "https://faktorxmensch.com/support",
      "/impressum": "https://faktorxmensch.com/impressum",
      "/datenschutz": "https://faktorxmensch.com/datenschutz",
      "/agb": "https://faktorxmensch.com/agb",
      "/kontakt": "https://faktorxmensch.com/kontakt",
      "/": "https://faktorxmensch.com",
    }
    return newUrls[url] || "https://faktorxmensch.com"
  }


  render() {
    return (
      <>
        <Header isDark={this.props.isDark == true} />
        {this.state.showPopup && (
          <div className="popup">
            <div className="popup-content" style={{ borderRadius: 15 }}>
              <span className="close" onClick={this.closePopup}>&times;</span>
              <h2 style={{ marginTop: 15, color: "orange", fontWeight: "bold", fontSize: 40 }}>News!</h2>
              <p>Dank Ihrer Unterstützung sind wir gewachsen und haben uns weiterentwickelt. Wir sind jetzt
                Faktor&times;Mensch.</p>
              <div className="popup-buttons" style={{ paddingBottom: 20, paddingTop: 5 }}>
                <a
                  href={this.getNewUrl()}
                  style={{
                    marginLeft: 0,
                    float: "right",
                    fontSize: 21,
                    width: "100%",
                    boxSizing: "border-box",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  className={"btn btn-lg btn-primary-outline "}>Zur neuen Webseite &rarr;</a>
              </div>
            </div>
          </div>
        )}


        <script jsx>{`
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: #fff;
    padding: 30px;
    width: 80%;
    max-width: 500px;
    position: relative;
    border-radius:15px !important;
    animation: fadeIn 0.5s;
}

.popup-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #DB8D36;
}

.popup-buttons button,
.popup-buttons .btn-primary-outline {
    margin-right: 10px;
    background: #fff;
    font-size: 1.4rem;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 25px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
        `}</script>

        <div id="page"
             className={this.props.hasDarkColorScheme ? "has-dark-color-scheme dark-scheme-" + this.props.hasDarkColorScheme : ""}>
          <main
            className={this.props.container !== undefined && this.props.container && "container"}>{this.props.children}</main>

          <footer style={{ background: "#444" }}>
            <a href="#" className="footer-backup">&uarr; Nach oben &uarr;</a>
            <Container>
              <Row className="footer-links">
                <Col sm={3}>
                  <h2>Services:</h2>
                  <Link to={"/services/webseite"}>Webseite</Link>
                  <Link to={"/services/imagefilm"}>Imagefilm</Link>
                  <Link to={"/services/apps"}>App Entwicklung</Link>
                </Col><Col sm={3}>
                <h2>Weitere Seiten:</h2>
                <Link to={"/services/custom-software"}>Software Lösungen</Link>
                <a href={"https://g.page/r/CbeLFgzPbz_EEBM/review"} target={"_blank"}>Google Rezension</a>
                <Link to={"/praktikum"}>Praktikum</Link>
              </Col><Col sm={3}>
                <h2>Auskunft:</h2>
                <Link to={"/impressum"}>Impressum</Link>
                <Link to={"/datenschutz"}>Datenschutz</Link>
                <Link to={"/agb"}>AGB</Link>
              </Col><Col sm={3}>
                <h2>Unterstützung:</h2>
                <Link to={"/tools/passgen"}>Passwörter</Link>
                <Link to={"/kontakt"}>Kontakt</Link>
                <Link to={"/support"}>Support</Link>
              </Col>
              </Row>
              <input
                style={{ color: "white", fontSize: "1rem", outline: "none", display: "none", autoComplete: "none" }}
                placeholder="Suchen Sie etwas bestimmtes?" onChange={(e) => {
                this.setState({ searchTerm: e.target.value })
              }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13)
                    window.location.href = "https://www.google.com/search?q=site:ziegenhagel.com+" + encodeURI(this.state.searchTerm)
                }} className="pagesearch form-control mt-4 container"></input>
              <div className="footer-contact container">
                <a
                  href="https://www.google.com/maps/place/Wilhelm-Holzamer-Stra%C3%9Fe+8,+55129+Mainz/@49.9620319,8.2703284,659m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47bd90cf372791ff:0x9f2acc62b89542e!8m2!3d49.9620285!4d8.2729033!16s%2Fg%2F11c0phpkq6?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"><FontAwesomeIcon
                  icon={faMap}></FontAwesomeIcon>&ensp;Mainz, Wilhelm-Holzamer-Str. 8</a>
                <a href="https://goo.gl/maps/HsCiXRLpwmwxnq6N8"><FontAwesomeIcon
                  icon={faMap}></FontAwesomeIcon>&ensp;Emmendingen, Brunnenstr. 1/1</a>
                <br />
                <a href={"tel:" + siteMetadata.phone}><FontAwesomeIcon
                  icon={faPhone}></FontAwesomeIcon>&ensp;{siteMetadata.phone}</a>
                <a href="mailto:info&#64;ziegenhagel.com"><FontAwesomeIcon
                  icon={faEnvelope}></FontAwesomeIcon>&ensp;info@ziegenhagel.com</a>
              </div>
              <p className="copyright text-center">&copy; 2014-{new Date().getFullYear()} Dominik Ziegenhagel Media. All
                rights reserved.</p>
            </Container>
          </footer>

          {false && <CookieConsent
            buttonText="Erlauben"
            declineButtonText="Ablehnen"
            enableDeclineButton
            cookieName="gatsby-gdpr-google-analytics">
            Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies
            und erfassen Daten.
            Weitere Informationen erhalten Sie in unserer <Link
            to={"/datenschutz#datenerfassung"}>Datenschutzerklärung</Link>.
          </CookieConsent>}

        </div>
      </>
    )
  }

}
